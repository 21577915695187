<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>招生老师添加/编辑</div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">

                    <el-form-item label="姓名" prop="F_VR_XINGM" :rules="[{required:true,message:'姓名不能为空',trigger: 'blur' }]"><el-input placeholder="请输入姓名" v-model="info.F_VR_XINGM"></el-input></el-form-item>
                    <el-form-item label="头像" prop="thumb"><el-upload class="avatar-uploader" :action="$api.advUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccess" >
                        <img v-if="info.F_VR_TOUX" :src="info.F_VR_TOUX" class="avatar-upload">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload></el-form-item>
                    <el-form-item label="性别" prop="F_TI_XINGB">
                        <el-select v-model="info.F_TI_XINGB" placeholder="选择性别">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="男" value="1"></el-option>
                            <el-option label="女" value="2"></el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="关联微信号" prop="F_IN_ZSLS_ID">
                        <el-select v-model="info.F_IN_YONGHGZH_ID" placeholder="请选择对应微信号" @change="selectChange()">
                            <el-option label="请选择对应微信号" :value="0"></el-option>
                            <el-option v-for="(v,k) in list"  :label="v.nickname" :key="k" :value="v.F_IN_ID"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="手机号码" prop="F_VR_SHOUJIHM" :rules="[{required:true,message:'手机号码不能为空',trigger: 'blur' }]"><el-input placeholder="请输入手机号码" v-model="info.F_VR_SHOUJIHM"></el-input></el-form-item>

                    <el-form-item label="微信二维码" prop="thumb"><el-upload class="avatar-uploader" :action="$api.advUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccesses" >
                        <img v-if="info.F_VR_WEIXEWM" :src="info.F_VR_WEIXEWM" class="avatar-upload">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload></el-form-item>

                    <el-form-item label="简介" prop="F_VR_JIANJ"><el-input type="textarea" placeholder="请输入简介" maxlength="200" show-word-limit v-model="info.F_VR_JIANJ"></el-input></el-form-item>


                    <el-form-item label="本月业绩目标" prop="F_VR_YEJMB" :rules="[{required:true,message:'本月业绩目标不能为空',trigger: 'blur' }]"><el-input placeholder="请输入本月业绩目标" v-model="info.F_VR_YEJMB"></el-input></el-form-item>
                    <el-form-item label="本月人数目标" prop="F_IN_RENSMB" :rules="[{required:true,message:'本月人数目标不能为空',trigger: 'blur' }]"><el-input placeholder="请输入本月人数目标" v-model="info.F_IN_RENSMB"></el-input></el-form-item>
                    <el-form-item label="当前阶段提成比例" prop="F_VR_DANGQJDTCBL" :rules="[{required:true,message:'当前阶段提成比例不能为空',trigger: 'blur' }]"><el-input placeholder="请输入当前阶段提成比例" v-model="info.F_VR_DANGQJDTCBL"></el-input></el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <!-- <el-button @click="resetForm('info')">重置</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                edit_id:0,
                info:{
                },
                list:[],
                upload_headers:{},
            };
        },
        watch: {},
        computed: {},
        methods: {
            resetForm:function(e){
                this.$refs[e].resetFields();
            },
            submitForm:function(e){
                let _this = this;

                // 验证表单
                this.$refs[e].validate(function(res){
                    if(res){
                        //  判断是Add 或者 Edit
                        let url = _this.$api.addZsls;
                        if(_this.edit_id>0){
                            url = _this.$api.editZsls+_this.edit_id;
                        }

                        if(_this.info.F_IN_GHZ_ID<=0){
                            return _this.$message.error('请选择关联微信号');
                        }



                        // Http 请求
                        _this.$post(url,_this.info).then(function(res){
                            if(res.code == 200){
                                _this.$message.success("编辑成功");
                                _this.$router.go(-1);
                            }else{
                                _this.$message.error("编辑失败");
                            }
                        });
                    }
                });
            },
            get_adv_info:function(){
                let _this = this;
                this.$get(this.$api.editZsls+this.edit_id).then(function(res){
                    _this.info = res.data.info;
                    _this.list = res.data.gzhgz;
                })

            },
            get_adv_list:function(){
                let _this = this;
                this.$get(this.$api.addZsls).then(function(res){
                    _this.list = res.data;
                })

            },
            selectChange:function(){
                this.$forceUpdate();
            },
            handleAvatarSuccess(res) {
                this.info.F_VR_TOUX = res.data;
                this.$forceUpdate();
            },
            handleAvatarSuccesses(res) {
                this.info.F_VR_WEIXEWM = res.data;
                this.$forceUpdate();
            },
        },
        created() {
            this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到

            if(!this.$isEmpty(this.$route.params.adv_position_id)){
                this.info.ap_id = this.$route.params.adv_position_id;
            }

            // 判断是否是编辑
            if(!this.$isEmpty(this.$route.params.id)){
                this.edit_id = this.$route.params.id;
            }

            if(this.edit_id>0){
                this.get_adv_info();
            }else{
               this.get_adv_list();
            }

        },
        mounted() {}
    };
</script>
<style lang="scss" scoped>
    .line{text-align: center}
</style>